/* eslint-disable promise/param-names */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
// eslint-disable-next-line prettier/prettier
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import firebase from 'firebase/app';
import { auth } from 'FirebaseFiles/Firebase';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import moment from 'moment-timezone';

import axios from 'axios';
import gapi from 'Gapi/gapi';
import { queryClient } from 'AppV2/configs/QueryClient';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';

import usersPagePermission from 'Common/pagePermission';
import resetSquadMemberData from 'Common/FillProfile/resetSquadMemberData';
import {
  setCustomClaim,
  createFirstCustomClaim,
  createUserPostObj,
} from 'Common/AuthContext/authContext';
import {
  IdTokenResultProps,
  setAxiosDefaultOptions,
  setAxiosDefaultToken,
  setAxiosDefaultWorkspaceId,
} from 'AppV2/common/setAxiosDefaultOptions';
import {
  sessionStorageIsFirstSignUp,
  sessionStorageIsSquadOwnerSession,
  sessionStorageSalesSignIn,
  sessionStorageShouldRedirectToClosePageAfterLogin,
  sessionStorageShouldRedirectToDashboard,
  sessionStorageUrlToRedirect,
  sessionStorageUserLogout,
} from 'Common/sessionStorage';

import setPopUpErrorThunk from 'Store/modules/popUpError/thunk';
import { setAdminDataThunk } from 'Store/modules/admin/adminData/thunk';

import {
  AuthContextValues,
  CurrentUser,
  CustomClaim,
  ShouldShowPostProjectPopUpStorageKey,
  UpdateCurrentUserProps,
  userAuthCacheMinutes,
  UserCredential,
} from './types';
import { ProviderDefaultProps } from 'AppV2/interfaces/providerDefaultProps';
import { WorkspaceIdCookieKey } from 'AppV2/services/Workspace';

import {
  APP_V2_PATHS,
  getOpenPages,
  LOGIN_PAGES,
  MEMBER_ROUTES,
  OWNER_ROUTES,
  URL_PATHS_WITHOUT_HEADER,
  URL_PATHS_WITHOUT_NAVBAR,
} from 'AppV2/assets/allUserRoleRoutes';

import { LoadingPage } from 'AppV2/components/LoadingPage';
import { IsCampaignFlowSessionStorageKey } from 'Pages/SquadOwnerAlternativeSignIn/Sales';
import { translateText } from 'AppV2/common/translateText';
import { ModalProvider } from 'AppV2/providers/ModalProvider';
import { Alert } from 'Components/Molecules/Alert';
import { useWorkspace } from 'AppV2/hooks/Workspace';
import { IsNewOwnerBySalesSignupSessionStorageKey } from 'AppV2/components/ModalFillCompanyInfosAlert';
import { localStorageSelfAdmission } from 'AppV2/services/Opportunities/PostSelfAdmission';
import { UserRoleEnum, UserRoleType } from 'AppV2/interfaces/userRole';
import { ResetPasswordActionCodeSessionStorageKey } from 'Pages/Service';
import { useStorageRedirects } from 'AppV2/hooks/StorageRedirects';

const AuthContext = createContext({} as AuthContextValues);

const AuthProvider = ({ children }: ProviderDefaultProps) => {
  // HOOOKS
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getStorageUrl, redirectTo } = useStorageRedirects();
  const { getSessionStorageWorkspaceSelectedForbiddens } = useWorkspace();

  // STATES
  const popUpObj = { isOpen: true, message: null, formMessage: '' };
  const currentUser = useRef<CurrentUser>(null);
  const [cookie] = useCookies();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const loginErrorMessage = useRef('');
  const [workspaceSelectedForbiddens, setWorkspaceSelectedForbiddens] =
    useState(getSessionStorageWorkspaceSelectedForbiddens());
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const [loadingLogout, setLoadingLogout] = useState(false);
  const [userCustomClaim, setUserCustomClaim] = useState<CustomClaim>();
  const [returnLoginMessage, setReturnLoginMessage] =
    useState<UserCredential | null>(null);
  const [returnSingUpMessage, setReturnSignUpMessage] =
    useState<UserCredential | null>(null);
  const [isAdminSignUpOk, setIsAdminSignUpOk] = useState(false);
  const [isFirstSocialLogin, setIsFirstSocialLogin] = useState<boolean>(false);
  const [customClaimFirstStageLoading, setCustomClaimFirstStageLoading] =
    useState(!!sessionStorage.getItem('isPostUsersRunning'));
  const [userIdToken, setUserIdToken] = useState<string | null>(null);
  const [isSuccessfulResetPassword, setIsSuccessfulResetPassword] = useState<
    boolean | string
  >('');
  const [isSuccessfulEmailChecked, setIsSuccessfulEmailChecked] =
    useState(false);
  const [isSuccessfulResetPasswordSent, setIsSuccessfulResetPasswordSent] =
    useState(false);

  // FUNCTIONS
  const setThisCustomClaim = useCallback(idTokenResult => {
    const customClaim = idTokenResult;
    setCustomClaim(customClaim, setUserCustomClaim);
    return customClaim;
  }, []);
  const updateCurrentUser = ({ ...props }: UpdateCurrentUserProps) => {
    if (props.key && props.value) {
      currentUser.current[props.key] = props.value;
      return;
    }

    if (props.keys && props.values) {
      props.keys.forEach((key, index) => {
        currentUser.current[key] = props.values[index];
      });
    }
  };
  const handleWithRedirectAfterLogin = useCallback(
    (regularRedirect: string, userRole = '') => {
      const shoudlRedirectToClosedPageAfterLogin =
        sessionStorageShouldRedirectToClosePageAfterLogin('get');

      if (shoudlRedirectToClosedPageAfterLogin) {
        const urlToRedirect = sessionStorageUrlToRedirect('get') as string;
        sessionStorageShouldRedirectToClosePageAfterLogin('remove');
        sessionStorageUrlToRedirect('remove');
        sessionStorageIsSquadOwnerSession('remove');

        const redirectPath =
          userRole === UserRoleEnum.squadOwner ? '/dashboard' : regularRedirect;

        const userRoleRoutes =
          userRole === UserRoleEnum.squadOwner ? OWNER_ROUTES : MEMBER_ROUTES;

        const isValidNavigationStage = userRoleRoutes.some(route =>
          urlToRedirect.startsWith(route),
        );

        return open(
          isValidNavigationStage ? urlToRedirect : redirectPath,
          '_self',
        );
      }

      if (
        regularRedirect.toLowerCase() === '/squadowner-profile' ||
        regularRedirect.toLowerCase() === 'squadowner-profile'
      ) {
        sessionStorageShouldRedirectToDashboard('set');
        // return history.push('/dashboard');
        // force reload to update invitedWorkspaces
        return open('/dashboard', '_self');
      }

      return open(regularRedirect, '_self');
    },
    [],
  );
  const handleWithLogin = useCallback(
    idTokenResult => {
      setLoadingLogin(true);

      if (idTokenResult.claims.userRole === UserRoleEnum.admin) {
        return open(idTokenResult.claims.navigationStage, '_self');
      }
      if (idTokenResult.claims.userRole === UserRoleEnum.squadMember) {
        const jobDetailsUrl = localStorageSelfAdmission('getJobDetailsUrl');
        if (jobDetailsUrl) {
          open(jobDetailsUrl, '_self');
          return setTimeout(
            () => localStorageSelfAdmission('removeStorages'),
            2100,
          );
        }
        if (!idTokenResult.claims.email_verified) {
          return history.push('/confirm-email');
        }
        if (idTokenResult.claims.navigationStage === 'squadMember-profile') {
          return handleWithRedirectAfterLogin(
            idTokenResult.claims.navigationStage,
            UserRoleEnum.squadMember,
          );
        } else {
          return open(idTokenResult.claims.navigationStage, '_self');
        }
      }
      if (idTokenResult.claims.userRole === UserRoleEnum.squadOwner) {
        if (idTokenResult.claims.navigationStage === 'squadOwner-register') {
          return history.push('/squadOwner-register');
        }

        if (
          idTokenResult.claims.navigationStage.toLowerCase() ===
            'squadowner-profile' ||
          idTokenResult.claims.navigationStage === '/project-brief-selection'
        ) {
          return handleWithRedirectAfterLogin(
            idTokenResult.claims.navigationStage,
            UserRoleEnum.squadOwner,
          );
        }

        return open(idTokenResult.claims.navigationStage, '_self');
      }
    },
    [handleWithRedirectAfterLogin, history],
  );
  const setCustomClaimFirstStage = useCallback(
    async (accountType: UserRoleType, adminData = {}) => {
      setCustomClaimFirstStageLoading(true);

      setTimeout(async () => {
        const isSquadOwner = accountType === UserRoleEnum.squadOwner;
        const isSalesSignIn = sessionStorageSalesSignIn('get');
        const isMatchmakingTrial = !!localStorage.getItem('MATCHMAKING_TRIAL');
        const isAssessmentOwner = !!localStorage.getItem('ASSESSMENT_OWNER');
        const isAssessmentsMember = !!localStorage.getItem(
          'ASSESSMENTS_INVITE_DATA',
        );

        let customClaimFlag = null;
        if (isMatchmakingTrial) {
          customClaimFlag = { key: 'matchmakingTrial', value: true };
        }
        if (isAssessmentOwner) {
          customClaimFlag = { key: 'assessmentOwner', value: true };
        }
        if (isAssessmentsMember) {
          customClaimFlag = { key: 'assessmentUser', value: true };
        }

        const customClaimData = createFirstCustomClaim({
          accountType: accountType,
          flag: customClaimFlag,
        });
        const fireStoreObj = createUserPostObj(
          auth.currentUser,
          accountType,
          adminData,
        );
        const response =
          isSquadOwner && !isMatchmakingTrial && !isAssessmentOwner
            ? { status: 200 }
            : await gapi.customClaim.post(customClaimData);

        if (response.status === 200) {
          const userResponse = await gapi.users.post(fireStoreObj);
          if (userResponse.status === 200) {
            const idTokenResult = await auth.currentUser.getIdTokenResult(true);

            const userToken = {
              ...idTokenResult,
              claims: {
                ...idTokenResult.claims,
                userRole: accountType,
              } as CustomClaim,
            };

            if (isSquadOwner) {
              if (isMatchmakingTrial) {
                userToken.claims.matchmakingTrial = true;
              }
              if (isAssessmentOwner) {
                userToken.claims.assessmentOwner = true;
              }

              userToken.claims.workspaceId = '';
            }

            currentUser.current = {
              ...auth.currentUser,
              claims: {
                ...userToken.claims,
              } as CustomClaim,
            };

            setAxiosDefaultOptions(
              'setAll',
              userToken as unknown as IdTokenResultProps,
            );
            setCustomClaimFirstStageLoading(false);

            if (isSquadOwner) {
              const isFirstSignUp = sessionStorageIsFirstSignUp('get');

              if (isFirstSignUp && !isSalesSignIn) {
                if (!isMatchmakingTrial && !isAssessmentOwner) {
                  localStorage.setItem(
                    ShouldShowPostProjectPopUpStorageKey,
                    'true',
                  );
                }
                sessionStorageIsFirstSignUp('remove');

                return history.push('/squadOwner-register');
              } else {
                const isCampaign = !!sessionStorage.getItem(
                  IsCampaignFlowSessionStorageKey,
                );

                return history.push(
                  isCampaign ? '/new-journey' : '/squadOwner-register',
                );
              }
            }
            if (idTokenResult.claims.userRole === UserRoleEnum.squadMember) {
              if (!idTokenResult.claims.email_verified) {
                return history.push('/confirm-email');
              }
              return history.push('/squadMember-register');
            }

            if (idTokenResult.claims.userRole === UserRoleEnum.admin) {
              dispatch(setAdminDataThunk(null));
              return handleWithRedirectAfterLogin('/cms-selection');
            }

            return history.push(idTokenResult.claims.navigationStage);
          }
          if (userResponse.status !== 200) {
            dispatch(
              setPopUpErrorThunk({
                ...popUpObj,
                message: String(userResponse),
              }),
            );
          }
        } else if (response.status !== 200) {
          if (accountType === UserRoleEnum.admin) {
            popUpObj.formMessage = 'unauthorized';
            popUpObj.message = response;
          }
          dispatch(setPopUpErrorThunk(popUpObj));
        }
      }, 3600);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleWithRedirectAfterLogin, history],
  );
  const updateDisplayName = useCallback(async (displayName: string) => {
    await firebase
      .auth()
      .currentUser?.updateProfile({
        displayName: displayName,
      })
      .catch(error => {
        throw new Error(error?.message || error);
      });
  }, []) as unknown as (displayName: string) => Promise<void>;
  const sendVerifyEmail = useCallback(async () => {
    return auth.currentUser
      ?.sendEmailVerification()
      .then(() => {
        console.info('Email de verificação enviando com sucesso');
      })
      .catch(error => {
        console.error(
          'Não foi possível enviar o e-mail de verificação. Error: ' +
            error.message,
        );
      });
  }, []);
  const verifyUSerEmail = useCallback(async (actionCode: string) => {
    const toReturn = {
      status: false,
      message: 'error',
    };

    await auth
      .applyActionCode(actionCode)
      .then(() => {
        toReturn.status = true;
        toReturn.message = '';
        // setIsSuccessfulEmailChecked(true);
      })
      .catch(error => {
        console.error('[ERROR]', error.message);

        toReturn.message = error.message;
        // setIsSuccessfulEmailChecked(error.message);
        console.error(error.message);
      });

    return toReturn;
  }, []);
  const userEmailIsVerified = useCallback(() => {
    const userEmailIsVerified = 'undefined';
    const currentUser = auth.currentUser;

    if (currentUser) {
      return auth.currentUser.emailVerified;
    }

    return userEmailIsVerified;
  }, []);
  const sendPasswordResetEmail = useCallback(async (email: string) => {
    return auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setIsSuccessfulResetPasswordSent(true);
      })
      .catch(error => {
        console.error(
          'Erro ao enviar email com reset de senha. ' + error.message,
        );
      });
  }, []);
  const firebaseResetPassword = useCallback(async (newPassword: string) => {
    await auth.currentUser
      .updatePassword(newPassword)
      .then(() => {
        setIsSuccessfulResetPassword(true);
      })
      .catch(error => {
        setIsSuccessfulResetPassword(false);
        console.error(error.message);
      });
  }, []);
  const resetPassword = useCallback(
    async (actionCode: string, newPassword: string) => {
      await auth
        .confirmPasswordReset(actionCode, newPassword)
        .then(() => {
          setIsSuccessfulResetPassword(true);
          localStorage.removeItem(ResetPasswordActionCodeSessionStorageKey);
        })
        .catch(error => {
          setIsSuccessfulResetPassword(error.message);
          console.error(error.message);
        });
    },
    [],
  );
  const shouldLoadingSalesSignup = useCallback(() => {
    const shouldLoadingSalesSignup = sessionStorageIsSquadOwnerSession('get');
    if (shouldLoadingSalesSignup) {
      return history.push('/sales-signup');
    }

    return history.push('/signup');
  }, [history]);
  const getCurrentUser = useCallback(async () => {
    await auth.currentUser?.reload();
    return auth.currentUser;
  }, []);
  const getUserEmail = useCallback(() => {
    let userEmail = 'undefined';

    if (auth.currentUser !== null) {
      userEmail = auth.currentUser.email;
    }

    return userEmail;
  }, []);
  const getUserDisplayName = useCallback(() => {
    let userDisplayName = 'undefined';
    if (auth.currentUser !== null) {
      userDisplayName = auth.currentUser.displayName;
    }
    return userDisplayName;
  }, []);
  const getIdToken = useCallback(async () => {
    try {
      const idTokenRequest = await auth?.currentUser?.getIdToken(true);
      return idTokenRequest;
    } catch (error: any) {
      console.error('[authContext:553][getIdToken:error]', error);
      throw error;
    }
  }, []);
  const getRefreshToken = useCallback(async () => {
    let token = '';

    const isSignInSignUpPages = [
      '/',
      '/login',
      '/signup',
      '/sales-signup',
    ].some(path => path === location.pathname);
    const authTime = apiV2Instance.defaults.headers?.authTime;
    const userAuthToken = apiV2Instance.defaults.headers?.Authorization;
    const isRevokedToken =
      moment.utc(new Date(authTime)).diff(moment.utc(new Date()), 'minutes') <=
      -userAuthCacheMinutes;

    if (!isSignInSignUpPages) {
      if (!userAuthToken || isRevokedToken) {
        await getIdToken().then(idToken => {
          token = idToken;
        });
      } else {
        token = userAuthToken;
      }
    }

    return token;
  }, [getIdToken, location.pathname]);
  const getCurrentUserPhoto = useCallback(() => {
    let userPhoto = '';
    const user = currentUser.current;

    if (user) {
      userPhoto =
        !!user?.photoURL &&
        (user.providerData[0].providerId !== 'facebook.com' ||
          (user.providerData[0].providerId === 'facebook.com' &&
            user.photoURL !== user.providerData[0].photoURL))
          ? user.photoURL
          : '';
    }

    return userPhoto;
  }, []);
  const getCustomClaim = useCallback(async (claim?: string) => {
    const currentUser = await auth.currentUser;
    if (currentUser !== null) {
      try {
        const tokenResult = await auth.currentUser.getIdTokenResult(true);
        if (claim === 'headerSubtitle') {
          if (tokenResult.claims.hasOwnProperty('headerSubtitle')) {
            return tokenResult.claims.headerSubtitle;
          }
        }
        if (claim === 'userRole') {
          if (tokenResult.claims.hasOwnProperty('userRole')) {
            return tokenResult.claims.userRole;
          }
        }
        if (claim === 'navigationStage') {
          if (tokenResult.claims.hasOwnProperty('navigationStage')) {
            return tokenResult.claims.navigationStage;
          }
        }
        return tokenResult.claims;
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error('getCustomClaim.error.message', error.message);
          return '';
        }
      }
    }
  }, []);
  const migrationOldUsers = useCallback(
    async userToken => {
      if (!userToken.claims.hasOwnProperty('userRole')) {
        const idToken = await Promise.resolve(getIdToken());
        axios
          .get(process.env.REACT_APP_FIREBASE_USERS_API, {
            headers: {
              Accept: 'application/json',
              authorization: idToken,
            },
          })
          .then(async res => {
            const userRes = res.data;
            const userHasUserRole = userRes?.login?.userRole ?? '';
            const userHasJobTitle = userRes?.experience?.jobTitle ?? '';
            const userHasLevel = userRes?.experience?.level ?? '';
            let squadMemberNavigaionStage = 'squadMember-profile';

            if (userHasUserRole === '') {
              return setIsFirstSocialLogin(true);
            }

            if (userHasUserRole === UserRoleEnum.squadMember) {
              if (userHasJobTitle === '') {
                squadMemberNavigaionStage = 'squadMember-register';
              }
              if (userHasJobTitle !== '') {
                if (userHasLevel === '') {
                  squadMemberNavigaionStage = 'squadMember-register';
                }
              }
            }

            const customClaimNavigationStage =
              userHasUserRole === UserRoleEnum.squadMember
                ? squadMemberNavigaionStage
                : 'squadOwner-profile';

            const customClaimHeaderSubtitle =
              userHasUserRole === UserRoleEnum.squadMember
                ? userHasJobTitle
                : '';

            const customClaimData = {
              idToken: await Promise.resolve(getIdToken()),
              customClaims: {
                userRole: userHasUserRole,
                navigationStage: customClaimNavigationStage,
                userIsAvailable: true,
                headerSubtitle: customClaimHeaderSubtitle,
              },
            };

            await axios
              .post(process.env.REACT_APP_FIREBASE_AUTH_API, customClaimData)
              .then(async () => {
                await auth.currentUser
                  .getIdTokenResult(true)
                  .then(async idTokenResult => {
                    history.push(idTokenResult.claims.navigationStage);
                  });
              });
          })
          .catch(error => {
            console.error('Error: ', error, 'errorMessage: ', error.message);
            return setIsFirstSocialLogin(true);
          });
      }
    },
    [getIdToken, history],
  );
  const checkPagePermission = useCallback(
    async (userType: string, urlPath: string, navigationStage: string) => {
      if (userType === UserRoleEnum.admin) {
        return;
      }
      if (
        userType !== UserRoleEnum.squadMember &&
        urlPath &&
        urlPath.startsWith('/squadMember-viewProfile')
      ) {
        return;
      }

      const shouldRedirect = await usersPagePermission(
        userType,
        urlPath,
        navigationStage,
      );

      if (sessionStorageShouldRedirectToClosePageAfterLogin('get')) {
        return history.push(sessionStorageUrlToRedirect('get') || '/');
      }
      if (shouldRedirect !== false) {
        return history.push(shouldRedirect);
      }
    },
    [history],
  );
  const observerUserToken = useCallback(() => {
    !isLoadingRequest &&
      !customClaimFirstStageLoading &&
      auth.onAuthStateChanged(async user => {
        const idToken = await user?.getIdToken();
        setAxiosDefaultToken(idToken);

        const urlPagina = window.location.pathname;
        const explodedUrl = urlPagina.split('/');
        let routerParam = null;
        if (explodedUrl.length > 1) {
          routerParam = explodedUrl[explodedUrl.length - 1];
        }
        if (explodedUrl.length === 4) {
          routerParam = `${explodedUrl[2]}/${explodedUrl[3]}`;
        }

        const openPages = getOpenPages(routerParam);
        if (
          !user &&
          openPages.some(url => urlPagina.slice(0, url.length).startsWith(url))
        ) {
          setLoadingAuthState(false);
          return;
        }

        if (user === null) {
          setLoadingAuthState(false);
          setUserCustomClaim(undefined);

          if (
            LOGIN_PAGES.includes(urlPagina) ||
            urlPagina.startsWith('/project-brief-view')
          ) {
            return;
          }
          const isUserLogout = sessionStorageUserLogout('get');
          if (isUserLogout) {
            sessionStorageUserLogout('remove');
            return history.push('/login');
          } else {
            // Handle with back to the future
            if (urlPagina.startsWith('/squadMember-viewProfile')) {
              sessionStorageIsSquadOwnerSession('set');
            }
            sessionStorageShouldRedirectToClosePageAfterLogin('set');
            sessionStorageUrlToRedirect('set', urlPagina);
            return (window.location.pathname = '/login');
          }
        }

        if (user !== null) {
          const idTokenResult = await user?.getIdTokenResult();
          currentUser.current = {
            ...auth.currentUser,
            claims: { ...idTokenResult.claims } as CustomClaim,
          };

          setAxiosDefaultOptions(
            'setHeaders',
            idTokenResult as unknown as IdTokenResultProps,
          );

          const WorkspaceIdCookie =
            cookie[`${WorkspaceIdCookieKey}${currentUser.current.uid}`];
          if (WorkspaceIdCookie) {
            setAxiosDefaultWorkspaceId(WorkspaceIdCookie);
          } else if (idTokenResult.claims?.workspaceId) {
            document.cookie = `${WorkspaceIdCookieKey}${currentUser.current.uid}=${idTokenResult.claims?.workspaceId}`;
          }

          setThisCustomClaim(idTokenResult);
          sessionStorageUserLogout('remove');
          loadingAuthState && setLoadingAuthState(false);
          loadingLogin && setLoadingLogin(false);

          let creationTimeLimit = new Date(user.metadata.creationTime);
          creationTimeLimit = new Date(
            creationTimeLimit.setSeconds(creationTimeLimit.getSeconds() + 6),
          );
          let authTimeLimit = new Date(idTokenResult?.authTime);
          authTimeLimit = new Date(
            authTimeLimit.setSeconds(authTimeLimit.getSeconds() + 1),
          );
          const userRole = idTokenResult.claims.userRole;
          const currentTime = new Date();

          // Handle with matchmaking new squadMember register flow
          if (
            currentUser?.current?.claims?.navigationStage === 'tests-selection'
          ) {
            if (location.pathname.startsWith('/jobs')) {
              return history.push('/tests-selection');
            }
          }
          // Handle with auto login
          if (LOGIN_PAGES.includes(urlPagina) && userRole) {
            return handleWithLogin(idTokenResult);
          }
          // Handle with social SignIn
          if (currentTime < creationTimeLimit) {
            const isSalesSignIn = sessionStorageSalesSignIn('get');
            if (isSalesSignIn) {
              setCustomClaimFirstStage(UserRoleEnum.squadOwner);
            }
            if (!sessionStorageIsSquadOwnerSession('get')) {
              if (
                getStorageUrl('MATCHMAKING_ACCEPT_INVITE') ||
                !!localStorage.getItem('PROJECT_MATCHMAKING_DATA')
              ) {
                setCustomClaimFirstStage(UserRoleEnum.squadMember);
              } else {
                setIsFirstSocialLogin(true);
              }
            }

            return;
          }
          // Handle with login: e-mail and social
          if (currentTime < authTimeLimit) {
            migrationOldUsers(idTokenResult);
            handleWithLogin(idTokenResult);
            return;
          }

          const idToken = await getIdToken();
          setUserIdToken(idToken);
          setCustomClaim(idTokenResult, setUserCustomClaim);

          if (openPages.some(url => urlPagina.startsWith(url))) {
            return;
          }

          // MATCHMAKING REDIRECT by USER WITH REGISTER
          if (getStorageUrl('MATCHMAKING_ACCEPT_INVITE')) {
            const ignoreRedirectPaths = [
              '/squadMember-register',
              '/squadMember-fill-profile',
              '/confirm-email',
              '/email-checked',
            ];
            return redirectTo('MATCHMAKING_ACCEPT_INVITE', ignoreRedirectPaths);
          }

          if (
            sessionStorage.getItem(IsNewOwnerBySalesSignupSessionStorageKey)
          ) {
            return;
          }
          const urlPath = `${history?.location?.pathname}${history?.location?.search}`;
          checkPagePermission(
            idTokenResult.claims.userRole,
            urlPath,
            idTokenResult.claims.navigationStage,
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingRequest,
    customClaimFirstStageLoading,
    history,
    cookie,
    setThisCustomClaim,
    loadingAuthState,
    loadingLogin,
    getIdToken,
    checkPagePermission,
    handleWithLogin,
    setCustomClaimFirstStage,
    migrationOldUsers,
  ]);

  // LOGIN SIGNUP - FUNCTIONS
  const emailSignup = useCallback(
    async (email, password, accountType, adminData = {}) => {
      await auth
        .createUserWithEmailAndPassword(email, password)
        .then(result => {
          setLoadingAuthState(true);
          sessionStorageIsFirstSignUp('set');
          setCustomClaimFirstStage(accountType, adminData);
          setReturnSignUpMessage(result);
        })
        .catch(error => {
          console.error(
            '[emailSignUp:createUserWithEmailAndPassword][ERROR]',
            error,
          );

          if (accountType === UserRoleEnum.admin) {
            throw new Error(error);
          }

          setReturnSignUpMessage(error?.code);
          if (error.code === 'auth/email-already-in-use') {
            loginErrorMessage.current = translateText(
              'ALERT:LOGIN_ERROR_MESSAGE_USER_ALREADY_USED',
            );
            setIsLoginError(true);
            setTimeout(() => {
              history.push('/login');
            }, 3000);
          }

          throw error;
        });
    },
    [history, setCustomClaimFirstStage],
  );
  const emailLogin = useCallback(
    async (email, password) => {
      let isLogginSuccess = true;

      await auth.signInWithEmailAndPassword(email, password).catch(error => {
        console.error('error catch: ', error);
        if (error.code === 'auth/user-not-found') {
          loginErrorMessage.current = translateText(
            'ALERT:LOGIN_ERROR_MESSAGE_USER_NOT_FOUND',
          );
          setTimeout(() => {
            history.push('/signup');
          }, 3000);
        }
        if (error.code === 'auth/wrong-password') {
          loginErrorMessage.current = translateText(
            'ALERT:LOGIN_ERROR_MESSAGE_WRONG_PASSWORD',
          );
        }
        isLogginSuccess = false;
        setIsLoginError(true);
      });

      return isLogginSuccess;
    },
    [history],
  );
  const googleLogin = useCallback(async (): Promise<void> => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });
    firebase.auth().useDeviceLanguage();

    await auth
      .signInWithPopup(googleProvider)
      .then(async result => {
        if (result.additionalUserInfo.isNewUser) {
          if (sessionStorageIsSquadOwnerSession('get')) {
            sessionStorageIsFirstSignUp('set');
            return setCustomClaimFirstStage(UserRoleEnum.squadOwner);
          } else {
            sessionStorageIsFirstSignUp('set');
            return setIsFirstSocialLogin(true);
          }
        } else {
          history.push('/signup');
          setIsFirstSocialLogin(true);
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  }, [history, setCustomClaimFirstStage]);
  const googleSignup = useCallback(
    async (accountType: string): Promise<void> => {
      const googleProvider = new firebase.auth.GoogleAuthProvider();

      googleProvider.setCustomParameters({
        prompt: 'select_account',
      });

      await new Promise((_, reject) => {
        firebase.auth().useDeviceLanguage();

        auth
          .signInWithPopup(googleProvider)
          .then(async result => {
            if (result.additionalUserInfo.isNewUser) {
              sessionStorageIsFirstSignUp('set');
            }
            setCustomClaimFirstStage(accountType as UserRoleType);
          })
          .catch(error => {
            console.error(error.message);
            reject(error);
          });
      });
    },
    [setCustomClaimFirstStage],
  );
  // const facebookLogin = async (): Promise<void> => {
  //   const facebookProvider = new firebase.auth.FacebookAuthProvider();
  //   firebase.auth().useDeviceLanguage();

  //   await auth
  //     .signInWithPopup(facebookProvider)
  //     .then(async result => {
  //       if (result.additionalUserInfo.isNewUser) {
  //         if (sessionStorageIsSquadOwnerSession('get')) {
  //           return setCustomClaimFirstStage(UserRoleEnum.squadOwner);
  //         } else {
  //           sessionStorageIsFirstSignUp('set');
  //           return setIsFirstSocialLogin(true);
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error.message);
  //     });
  // };
  // const facebookSignup = async (accountType: string): Promise<void> => {
  //   const facebookProvider = new firebase.auth.FacebookAuthProvider();

  //   await new Promise((_, reject) => {
  //     firebase.auth().useDeviceLanguage();

  //     auth
  //       .signInWithPopup(facebookProvider)
  //       .then(async result => {
  //         if (result.additionalUserInfo.isNewUser) {
  //           sessionStorageIsFirstSignUp('set');
  //           return setCustomClaimFirstStage(accountType);
  //         }
  //       })
  //       .catch(error => {
  //         console.error(error.message);
  //         reject(error);
  //       });
  //   });
  // };
  const logout = useCallback(async () => {
    if (currentUser.current) {
      setLoadingLogout(true);
      setIsFirstSocialLogin(false);
      await auth.signOut().then(() => {
        history.push('/login');
        setUserIdToken(null);
        currentUser.current = null;
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          queryClient.removeQueries();
          resetSquadMemberData.clearCacheData();
          sessionStorageUserLogout('set');
          setLoadingLogout(false);
        }, 2100);
      });
    }
  }, [history]);

  // APP_DEFAULT_VARIABLES
  const defaultAxiosHeaders = useMemo(() => apiV2Instance.defaults.headers, []);

  const isAuth = useMemo(
    () => !!defaultAxiosHeaders?.Authorization,
    [defaultAxiosHeaders?.Authorization],
  );

  const isInitialUrlPath = useMemo(() => location.pathname === '/', [location]);

  const isAppV2Paths = useMemo(
    () =>
      APP_V2_PATHS.some(path => {
        if (location.pathname === '/matchmaking-signup') {
          return false;
        }

        return location.pathname.startsWith(path);
      }),
    [location.pathname],
  );

  const isNewOwnerLoggedInProjectBriefFlow = useMemo(
    () =>
      location.pathname.startsWith('/project-brief') &&
      (currentUser?.current?.claims?.navigationStage ===
        'project-brief-selection' ||
        !currentUser?.current?.claims?.navigationStage),
    [location.pathname],
  );

  const shouldShowHeader = useMemo(
    () =>
      isAuth &&
      !isNewOwnerLoggedInProjectBriefFlow &&
      !isInitialUrlPath &&
      !URL_PATHS_WITHOUT_HEADER.some(path => location.pathname.includes(path)),
    [
      isAuth,
      isInitialUrlPath,
      isNewOwnerLoggedInProjectBriefFlow,
      location.pathname,
    ],
  );

  const shouldShowNavBar = useMemo(
    () =>
      isAuth &&
      !isNewOwnerLoggedInProjectBriefFlow &&
      !isInitialUrlPath &&
      userCustomClaim?.navigationStage !== 'tests-selection' &&
      !URL_PATHS_WITHOUT_NAVBAR.some(path => location.pathname.includes(path)),
    [
      isAuth,
      isInitialUrlPath,
      isNewOwnerLoggedInProjectBriefFlow,
      location.pathname,
      userCustomClaim?.navigationStage,
    ],
  );

  // EFFECTS
  useEffect(() => {
    observerUserToken();
  }, [observerUserToken]);

  useEffect(() => {
    // should redirect to /squadMember-register or /confirm-email
    // if is user loged without profile register filled or no user email verified
    let user = currentUser.current;
    const allowedPathsWithoutConfirmEmail = [
      '/squadMember-register',
      '/jobs',
      '/squadMember-profile',
      '/squadMember-fill-profile',
      '/confirm-email',
      '/email-checked',
      '/service',
    ];
    if (
      !allowedPathsWithoutConfirmEmail.some(path =>
        path.startsWith(location.pathname),
      ) &&
      isAuth &&
      user?.claims?.userRole === UserRoleEnum.squadMember &&
      user?.claims?.navigationStage === 'squadMember-register' &&
      !user?.displayName
    ) {
      return history.push(
        user?.emailVerified ? '/squadMember-register' : '/confirm-email',
      );
    }

    return () => {
      user = null;
    };
  }, [isAuth, currentUser, location, history]);

  useEffect(() => {
    if (
      isAuth &&
      getStorageUrl('MATCHMAKING_ACCEPT_INVITE') &&
      location.pathname.startsWith('/squadMember-profile')
    ) {
      return history.push(getStorageUrl('MATCHMAKING_ACCEPT_INVITE'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  const value = {
    defaultAxiosHeaders,
    isAuth,
    isAppV2Paths,
    shouldShowHeader,
    shouldShowNavBar,
    isNewOwnerLoggedInProjectBriefFlow,
    currentUser: currentUser.current,
    workspaceSelectedForbiddens,
    isLoginError,
    setIsLoginError,
    loginErrorMessage,
    setWorkspaceSelectedForbiddens,
    setUserIdToken,
    userCustomClaim,
    setUserCustomClaim,
    returnLoginMessage,
    returnSingUpMessage,
    isFirstSocialLogin,
    setCustomClaimFirstStage,
    setReturnSignUpMessage,
    setIsAdminSignUpOk,
    isAdminSignUpOk,
    setReturnLoginMessage,
    emailSignup,
    emailLogin,
    googleSignup,
    googleLogin,
    // facebookSignup,
    // facebookLogin,
    sendVerifyEmail,
    getUserEmail,
    userEmailIsVerified,
    sendPasswordResetEmail,
    getIdToken,
    getCurrentUser,
    logout,
    getUserDisplayName,
    updateDisplayName,
    // reloadUserCredential,
    firebaseResetPassword,
    checkPagePermission,
    getCustomClaim,
    userIdToken,
    isSuccessfulResetPassword,
    setIsSuccessfulResetPassword,
    // confirmUserEmail,
    isSuccessfulEmailChecked,
    verifyUSerEmail,
    resetPassword,
    isSuccessfulResetPasswordSent,
    setIsSuccessfulResetPasswordSent,
    handleWithRedirectAfterLogin,
    shouldLoadingSalesSignup,
    customClaimFirstStageLoading,
    getRefreshToken,
    getCurrentUserPhoto,
    isLoadingRequest,
    setIsLoadingRequest,
    updateCurrentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      <ModalProvider>
        {isLoginError && (
          <Alert
            title={translateText('ALERT:LOGIN_ERROR_MESSAGE_TITLE')}
            description={loginErrorMessage.current}
            alertType="error"
          />
        )}
      </ModalProvider>

      {(customClaimFirstStageLoading || loadingLogout || loadingLogin) && (
        <LoadingPage />
      )}

      {!loadingAuthState && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
};

export { AuthContext, AuthProvider, useAuth };
