import { TemplatesKeysEnum } from 'AppV2/hooks/TemplatesConfig/types';
import { colors } from 'AppV2/styles/colors';
import { motion } from 'framer-motion/dist/framer-motion';
import styled, { CSSObject, css } from 'styled-components';

// DEFAULT
export const LoadingPageContainer = styled.div.attrs({
  className: 'loading-content flexbox-center',
})<{ styles: CSSObject }>`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: ${colors.background200};

  ${({ styles }) => styles}
`;

// MOTION ANIMATION
export const MotionDivWrapper = styled(motion.div)<{
  templateKey: TemplatesKeysEnum;
  version?: 'V1' | 'V2';
}>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ templateKey, version }) => {
    if (version === 'V1') {
      if (templateKey === TemplatesKeysEnum.HUNTERS_99) {
        return css`
          > div {
            width: 224px;
          }
        `;
      }

      if (templateKey === TemplatesKeysEnum.GROWYX) {
        return css`
          > div {
            width: 100%;
            max-width: 336px;
          }

          @media screen and (max-width: 480px) {
            > div {
              max-width: 224px;
            }
          }
        `;
      }
    }

    if (version === 'V2') {
      if (templateKey === TemplatesKeysEnum.HUNTERS_99) {
        return css`
          > div {
            min-width: 224px;
          }
        `;
      }
    }
  }}
`;

export const MotionAnimation = {
  initial: { scale: 0.1, opacity: 0.1 },
  animate: { scale: 1, opacity: 1, animationDelay: '1.8s' },
};

// LAZY
export const LazyPageContainer = styled(LoadingPageContainer)<{
  styles: CSSObject;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ styles }) => styles}
`;

export const BulletsWrapper = styled(motion.div)`
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

const BULLET_COLORS = {
  [TemplatesKeysEnum.GROWYX]: {
    0: '#4125c2',
    1: '#d71d1d',
    2: '#f2bd1d',
  },
  [TemplatesKeysEnum.HUNTERS_99]: {
    0: '#FF6B00',
    1: '#FF7D25',
    2: '#FFB07F',
  },
};
const BULLET_ANIMATION = {
  0: 'jumpingAnimation 0.6s 0.1s ease-in infinite',
  1: 'jumpingAnimation 0.6s 0.2s ease-in infinite',
  2: 'jumpingAnimation 0.6s 0.3s ease-in infinite',
};
export const BulletLoader = styled.span<{
  bulletIndex: number;
  templateKey: TemplatesKeysEnum;
}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inherit;

  @keyframes jumpingAnimation {
    0% {
      transform: scale(1, 1) translate3d(0, 0, 0);
    }
    50% {
      transform: scale(1.2, 0.8) translate3d(0, 15px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  ${({ bulletIndex, templateKey }) => {
    return css`
      background-color: ${BULLET_COLORS[templateKey][bulletIndex]};
      animation: ${BULLET_ANIMATION[bulletIndex]};
    `;
  }}
`;

// BASIC
export const BasicMotionDivWrapper = styled(motion.div)`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  .lottie-player {
    width: 300px;
    height: 264.63px;
    max-width: 300px;
    max-height: 264.63px;
  }
`;
